import '../pages/App.css';
import HomeSection from './HomeSection/components/HomeSection';
import Header from './../components/Header/Header';
import Footer from './../components/Footer/Footer';
// import Aplicativo from './Aplicaitvo/Aplicativo';
// import Vantagens from './Vantagens/Vantagens';
// import PainelAdministrativo from './PainelAdministrativo/PainelAdministrativo';
// import Sis from './SisPncd/Sis';
import ScrollToTopButton from './../components/ScrollToTopButton/ScrollToTopButton';
function App() {
  return (
    <div className="App">
      <Header />
      <HomeSection/>
      {/* <Aplicativo/>
      <PainelAdministrativo/>
      <Vantagens/>
      <Sis/> */}

      <Footer />
      <ScrollToTopButton />
    </div>
  );
}

export default App;
