import "../HomeSection.css";
import fachada from '../../../assets/Fachada.png'

const HomeSection = () => {
  return (
    <section id="topo">
      <div className="home-section">
        <div className="text-content">
          <h1>Cansado de pagar caro por um atendimento ruim?</h1>
          <p>Venha para a Pedraltech e experimente qualidade com preços justos!</p>
        </div>
        <img className="pncd" src={fachada} alt="Imagem do notebook"/>
      </div>
    </section>
  );
};

export default HomeSection;
