import './Header.css';
import logo from '../../assets/LOGO.png';
import { useState } from 'react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header>
      <div className="header-main">
        <button className="menu-button" onClick={toggleMenu}>
          &#9776; {/* Icone do menu */}
        </button>
        <img src={logo} alt="Logo" className="header-logo" />
        <h3>PEDR@LTECH</h3>
      </div>
      <nav className={`sidebar ${isMenuOpen ? 'open' : ''}`}>
        <a href="#topo" onClick={toggleMenu}>Início</a>
        <a href="#app" onClick={toggleMenu}>Quem Somos</a>
        <a href="#painel" onClick={toggleMenu}>Local</a>
        <a href="#contato" onClick={toggleMenu}>Fale Conosco</a>
      </nav>
    </header>
  );
};

export default Header;
